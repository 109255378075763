<template>
  <div>
    <v-container style="height: 100dvh" class="bg-primary" v-if="loading" fill-height fluid>
      <v-row class="fill-height" align="center" justify="center">
        <v-col>
          <Loading style="background: transparent" />
        </v-col>
      </v-row>
    </v-container>
    <div v-else-if="!!settingsInfo" class="home-section">
      <section :style="platformBackground">
        <v-parallax class="landing-img" style="height: 100dvh">
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12" style="margin-top: auto">
              <v-container fluid>
                <v-layout justify-center align-center>
                  <v-flex shrink>
                    <v-img contain max-height="350" :src="settingsInfo.platform_project_icon"></v-img>
                  </v-flex>
                </v-layout>
              </v-container>
              <div class="text-h3 font-weight-bold mb-4">
                {{ settingsInfo.platform_project_title }}
              </div>
              <h2 class="subheading white--text">
                {{ settingsInfo.platform_ide_intro_text }}
              </h2>
            </v-col>
            <v-layout justify-center style="margin-top: auto; margin-bottom: 65px">
              <v-card-actions>
                <v-fab-transition>
                  <v-btn rounded outlined dark x-large @click="scrollToElement">
                    <v-icon>mdi-arrow-down</v-icon>
                    {{ $t('global.apps') }}
                  </v-btn>
                </v-fab-transition>
              </v-card-actions>
            </v-layout>
          </v-row>
        </v-parallax>
      </section>
      <section ref="topMostElement">
        <v-container class="bg-primary pa-8" fluid>
          <v-row align="center" class="bg-primary">
            <v-col class="text-center" cols="12">
              <div class="text-h4 font-weight-bold mb-4 mt-8 white--text text-uppercase">
                {{ $t('global.apps') }}
              </div>
              <v-divider color="grey"></v-divider>
            </v-col>
          </v-row>
          <v-row align="center" class="mb-5">
            <v-col class="text-center" cols="12">
              <v-container fluid>
                <h2 class="subtitle-2 white--text">
                  {{ settingsInfo.platform_ide_intro_text_apps }}
                </h2>
              </v-container>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" class="mb-8">
            <v-col v-for="item in packages" :key="item.id" cols="12" sm="6" md="6" lg="3" xl="3">
              <AppTile :item="item" />
            </v-col>
          </v-row>
        </v-container>
        <v-row align="center" class="pa-0 ma-0">
          <v-col cols="12" class="text-center pa-0 ma-0">
            <div class="white--text ma-0">
              <v-container fluid class="about-bg pa-10">
                <h4 class="subheading">
                  Copyright © {{ settingsInfo.platform_project_title }}
                  {{ currentYear }}
                </h4>
                <h4 class="subheading">
                  <a class="white--text" :href="settingsInfo.platform_company_website">
                    {{ settingsInfo.platform_company_name }}
                  </a>
                </h4>
                <h4 class="subheading">
                  {{ settingsInfo.platform_company_address }}
                </h4>
                <h4 class="subheading">
                  {{ settingsInfo.platform_company_postal_code }}
                </h4>
                <h4 class="subheading">
                  {{ $t('global.tel') }}:
                  <a class="white--text" :href="telephone">
                    {{ settingsInfo.platform_company_telephone }}
                  </a>
                  | {{ $t('global.email') }}:
                  <a class="white--text" :href="mailto">
                    {{ settingsInfo.platform_company_email }}
                  </a>
                </h4>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </section>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/General/Loading";
import AppTile from "@/components/Home/AppTile";

export default {
  components: { Loading, AppTile },
  computed: {
    packages() {
      return this.$store.state.packages.list;
    },
    settingsInfo() {
      return this.$store.state.settings.settingsInfo;
    },
    platformBackground() {
      return { background: `url("${this.settingsInfo?.platform_background}")` };
    },
    telephone() {
      return `tel: ${this.settingsInfo.platform_company_telephone}`;
    },
    mailto() {
      return `mailto: ${this.settingsInfo.platform_company_email}`;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  async mounted() {
    this.$setBaseTheme();
    await this.$store.dispatch("packages/GET_PACKAGES");
    await this.$store.dispatch("settings/GET_SETTINGS_INFO");
    this.loading = false;
  },
  data: () => ({
    loading: true,
  }),
  methods: {
    scrollToElement() {
      const el = this.$refs.topMostElement;
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.home-section {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

<style>
.landing-img .v-parallax__image-container {
  backdrop-filter: brightness(60%) !important;
}

.landing-img .v-parallax__image {
  display: none !important;
}

.about-bg {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
