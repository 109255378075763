<template>
  <v-card v-if="!!item" class="mx-auto" :color="item.color" dark>
    <v-card flat :color="item.color" class="pt-2">
      <v-img
        contain
        height="200"
        :src="getThumb(item.id)"
        :alt="item.thumbnail"
      >
        <template v-slot:placeholder>
          <Loading />
        </template>
      </v-img>
    </v-card>
    <v-card-title class="pt-2 pb-2">{{ item.name }}</v-card-title>
    <v-card-text class="pt-3" style="position: relative">
      <div
        class="font-weight-light text-h7 mb-2 pr-2"
        style="height: 120px; overflow-y: auto"
      >
        {{ item.description }}
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            block
            v-bind="attrs"
            v-on="on"
            outlined 
            dark 
            large
            rounded
            :to="{ name: 'app', params: { id: item.code } }"
            class="font-weight-bold"
          >
            {{ $t('global.open') }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('global.open') }} {{ item.name }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>
<script>
import configs from "@/helpers/configs";
import Loading from "@/components/General/Loading";
export default {
  props: ["item"],
  components: { Loading },
  methods: {
    getThumb(id) {
      return `${configs.getApiUrl()}/v1/packages/${id}/thumbnail`;
    },
  },
};
</script>


<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: #000000;
}
</style>